import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, Zoom /*, Button, Grow, Slide */ } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

// const typeAlert = [ "error", "info", "success", "warning"];
const directionAlert = ['left', 'up', 'right', 'down'];
const positionAlert = ['top', 'bottom', 'center', 'right', 'left'];
const timeDuration = 3000;

const positions = {
	tr: ['top', 'right'],
	br: ['bottom', 'right'],
	tl: ['top', 'left'],
	bl: ['bottom', 'left'],
};

function TransitionSlide(props) {
	// return <Slide {...props} direction={directionAlert[0]} />;
	// return <Grow {...props} direction={directionAlert[0]} />;
	return <Zoom {...props} direction={directionAlert[0]} />;
}

const AlertMessage = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const Alert = props => {
	const { message, open, setOpen, type, position = 'tr', duration } = props;
	const [transition] = React.useState(() => TransitionSlide);

	// const handleClick = (Transition) => () => {
	// setOpen(true);
	// };

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setOpen(false);
	};

	return (
		<div>
			{/* <Button onClick={handleClick(TransitionSlide)}>Right</Button> */}

			<Snackbar
				open={open}
				onClose={handleClose}
				TransitionComponent={transition}
				// message="I love snacks"
				key={transition ? transition.name : ''}
				anchorOrigin={{ vertical: positions[position][0], horizontal: positions[position][1] }}
				autoHideDuration={duration ?? timeDuration}
			>
				<AlertMessage onClose={handleClose} severity={type /* typeAlert[2] */} sx={{ width: '100%' }} variant='filled'>
					<div style={{ whiteSpace: 'pre-line' }}>{message}</div>
				</AlertMessage>
			</Snackbar>
		</div>
	);
};

Alert.propTypes = {
	message: PropTypes.string,
	setOpen: PropTypes.func,
	type: PropTypes.string,
	open: PropTypes.bool,
	position: PropTypes.string,
	duration: PropTypes.number,
};

export default Alert;
