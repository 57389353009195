import { ACTIVATE_ALERT, DEACTIVATE_ALERT } from '../../consts/utilsTypes';

const initialState = {
	open: false,
	message: '',
	type: 'success',
	position: 'tr',
};

function utilsReducer(state = initialState, action = {}) {
	switch (action.type) {
		case ACTIVATE_ALERT:
			return {
				...state,
				open: true,
				message: action.payload.message,
				type: action.payload.type,
				position: action.payload.position ?? 'tr',
				duration: action.payload.duration
			};
		case DEACTIVATE_ALERT:
			return { ...state, open: false };

		default:
			return state;
	}
}

export default utilsReducer;
